<template>
  <div>
    <section class="max-w-6xl px-6 py-20 mx-auto text-center text-gray-600">
      <div class="py-10 text-center">
        <h1 class="text-4xl font-bold text-ghc-primary">Our Mission</h1>
      </div>
      <p class="text-lg text-center">
        <VueShowdown :markdown="`${mission.content}`" />
      </p>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../Api";

export default {
  name: "Mission",
  data() {
    return {
      mission: [],
      loading: true,
      error: null,
    };
  },
  async mounted() {
    try {
      const response = await axios.get(`${baseUrl}/mission`);
      this.mission = response.data;
      this.loading = false;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style></style>
