<template>
  <div>
    <header class="relative bg-fixed bg-center bg-no-repeat bg-cover">
      <!-- Overlay Background + Center Control -->
      <div
        class="flex items-center justify-center bg-black bg-opacity-50 hero"
        style="background: rgba(0, 0, 0, 0.5)"
      >
        <div class="flex flex-col mx-2 space-y-6 text-center"></div>
      </div>
    </header>
    <section class="max-w-4xl px-6 py-20 mx-auto">
      <div class="legals">
        <VueShowdown :markdown="`${privacy.content}`" />
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
  name: "PrivacyPolicy",
  data() {
    return {
      privacy: "",
    };
  },
  async mounted() {
    try {
      const response = await axios.get(`${baseUrl}/privacy-policy`);
      this.privacy = response.data;

      this.loading = false;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style scoped>
header {
  background-image: url("../assets/header.png");
  min-height: 170px;
}
.hero {
  min-height: 170px;
}
</style>
