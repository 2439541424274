<template>
   <div>
      <header class="relative bg-fixed bg-center bg-no-repeat bg-cover">
         <!-- Overlay Background + Center Control -->
         <div class="flex items-center justify-center bg-black bg-opacity-50 hero" style="background:rgba(0,0,0,0.5);">
            <div class="flex flex-col mx-2 space-y-6 text-center">
               <h1 class="text-3xl text-gray-100 uppercase md:text-4xl">
                  News
               </h1>
            </div>
         </div>
      </header>
      <section class="max-w-6xl px-6 py-20 mx-auto text-gray-600">
         <div class="py-10 text-center">
            <h1 class="text-4xl font-bold text-ghc-primary">
               News
            </h1>
         </div>
         <div class="text-center">
            <p>
               Latest regional and global healthcare news coming your way. Stay tuned!
            </p>
         </div>
      </section>
   </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
   name: "News",
   data() {
      return {
         news: "",
      };
   },
   methods: {
      getBaseUrl() {
         return baseUrl;
      },
   },
//    async mounted() {
//       try {
//          const news = await axios.get(`${baseUrl}/news`);
//          this.news = news.data;
//       } catch (error) {
//          this.error = error;
//       }
//    },
};
</script>

<style scoped>
header {
   background-image: url("../assets/header.png");
   min-height: 500px;
}
.hero {
   min-height: 500px;
}
</style>