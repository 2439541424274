<template>
  <div>
    <header class="relative h-screen bg-fixed bg-center bg-no-repeat bg-cover">
      <!-- Overlay Background + Center Control -->
      <div
        class="flex items-center justify-center h-screen bg-black bg-opacity-50"
        style="background: rgba(0, 0, 0, 0.5)"
      >
        <div class="flex flex-col mx-2 space-y-6 text-center">
          <h1
            class="inline-block text-4xl font-extrabold text-gray-100 uppercase md:text-6xl"
          >
            Global healthcare
          </h1>
          <h1
            class="inline-block text-4xl font-extrabold text-gray-100 uppercase md:text-6xl"
          >
            consultancy
          </h1>
          <h2
            class="text-xl font-extrabold leading-tight text-gray-200 uppercase md:text-2xl"
          >
            Consulting from the <br />
            grassroots!
          </h2>
        </div>
      </div>
    </header>
    <section class="px-6">
      <div
        class="max-w-6xl p-10 mx-auto my-20 text-center bg-gray-100 rounded-lg"
      >
        <h1 class="text-2xl">
          "HEALTHCARE PRIORITIES FOR AFRICA SHOULD BE SET BY AFRICANS."
        </h1>
        <div class="pt-8">
          <h2 class="text-3xl font-extrabold">GHC</h2>
          <h3 class="text-xl italic font-mediam">
            <!-- Your assistant in your journey in healthcare. -->
            GHC brings local experts as well as those in the African diaspora
            who are recognized in their fields to improve healthcare services,
            healthcare policy, medical education and research in Africa. As your
            trusted partners, our experts treat every challenge in the sector as
            an opportunity for a better Africa.
          </h3>
        </div>
      </div>
    </section>
    <WhoWeHelp></WhoWeHelp>
    <OurBlief></OurBlief>
    <WhatMakesUsDifferent></WhatMakesUsDifferent>
    <Story></Story>
    <Mission></Mission>
    <Values></Values>
    <Clients></Clients>
    <OurExperts></OurExperts>
    <News></News>
    <Subscribe></Subscribe>
  </div>
</template>

<script>
import WhoWeHelp from "../components/aboutGhc/WhoWeHelp.vue";
import OurBlief from "../components/aboutGhc/OurBelief.vue";
import WhatMakesUsDifferent from "../components/aboutGhc/WhatMakesUsDifferent.vue";
import Story from "../components/aboutGhc/Story.vue";
import Mission from "../components/aboutGhc/Mission.vue";
import Clients from "../components/aboutGhc/Clients.vue";
import Values from "../components/aboutGhc/Values.vue";
import OurExperts from "../components/aboutGhc/OurExperts.vue";
import News from "../components/aboutGhc/LatestNews.vue";
import Subscribe from "../components/aboutGhc/Subscribe.vue";

export default {
  name: "AboutUs",
  components: {
    WhoWeHelp,
    OurBlief,
    WhatMakesUsDifferent,
    Story,
    Mission,
    Clients,
    Values,
    OurExperts,
    News,
    Subscribe,
  },
  data() {
    return {
      loadingImage: require("../assets/curve-Loading.gif"),
    };
  },
};
</script>
<style scoped>
header {
  background-image: url("../assets/header.png");
}
</style>
