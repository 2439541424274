<template>
   <div class="bg-ghc-jetBlack">
      <section class="max-w-6xl px-6 py-20 mx-auto">
         <div class="py-10 text-center">
            <h1 class="text-4xl font-bold text-ghc-primary">
               Our Values
            </h1>
         </div>
         <div class="flex flex-wrap items-center justify-between px-10 py-20 space-x-16 text-gray-200">
            <div  v-for="value in values" :key="value.id">
               <img :src="getBaseUrl()+value.image.url" :alt="value.Title" class="h-24 mb-4" />
               <h2 class="mb-2 text-lg font-bold">
                  {{ value.title }}
               </h2>
            </div>
         </div>
      </section>
   </div>
</template>
<script>
import axios from "axios";
import { baseUrl } from "../../Api";

export default {
   name: "Values",
   data() {
      return {
         values: [],
         error: null,
      };
   },
   methods: {
      getBaseUrl() {
         return baseUrl;
      }
   },
   async mounted() {
      try {
         const response = await axios.get(`${baseUrl}/values`);
         this.values = response.data;
      } catch (error) {
         this.error = error;
      }
   },
};
</script>
<style scoped>
</style>
