<template>
   <section class="text-gray-600">
      <div class="max-w-2xl py-20 mx-auto">
         <div class="flex flex-col w-full mb-4 text-center">
            <h1 class="text-3xl font-semibold text-ghc-primary">
               Subscribe to our newsletter
            </h1>
         </div>
         <!-- <div class="flex flex-col items-end w-full px-8 mx-auto space-y-4 lg:w-2/3 sm:flex-row sm:space-x-4 sm:space-y-0 sm:px-0">
      <div class="relative flex-grow w-full">
        <input type="email" id="email" name="email" placeholder="Enter your email" class="w-full px-3 py-1 text-base leading-8 text-gray-700 transition-colors duration-200 ease-in-out bg-gray-100 bg-opacity-50 border border-gray-300 rounded outline-none focus:border-ghc-primary focus:bg-transparent">
      </div>
      <button class="px-8 py-2 text-lg text-white border-0 rounded bg-ghc-primary focus:outline-none hover:opacity-90"> Subscribe </button>
    </div> -->
         <div class="p-10 ">
            <ValidationObserver ref="form">
               <form @submit.prevent="onSubmit">
                  <div class="flex flex-wrap">
                     <div class="w-full p-2 mb-2 sm:w-1/2">
                        <div class="relative">
                           <label for="fName" class="block mb-2 font-medium">
                              First Name
                           </label>
                           <ValidationProvider name="First name" rules="required|alpha" v-slot="{errors }">
                              <input type="text" id="fName" v-model="fName" name="firstName" placeholder="First name" class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-ghc-primary focus:ring-1 focus:ring-ghc-primary" />
                              <span class="text-sm text-red-600">{{ errors[0] }}</span>
                           </ValidationProvider>
                        </div>
                     </div>
                     <div class="w-full p-2 mb-2 sm:w-1/2">
                        <div class="relative">
                           <label for="lName" class="block mb-2 font-medium">
                              Last Name
                              <!-- bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 -->
                           </label>
                           <ValidationProvider name="Last name" rules="required|alpha" v-slot="{ errors }">
                              <input type="text" id="lName" v-model="lName" name="lastName" placeholder="Last name" class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-ghc-primary focus:ring-1 focus:ring-ghc-primary" />
                              <span class="text-sm text-red-600">{{ errors[0] }}</span>
                           </ValidationProvider>
                        </div>
                     </div>
                     <div class="w-full p-2 mb-2">
                        <div class="relative">
                           <label for="title" class="block mb-2 font-medium">
                              Title
                           </label>
                            <ValidationProvider name="Title" rules="required" v-slot="{ errors }">
                           <select name="title" v-model="title" id="title" class="w-full px-3 py-1 text-sm leading-8 text-gray-700 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500">
                              <option value="">Title</option>
                              <!-- <option v-for="title in titles" :key="title.id" :value="title.range">
                                 {{ title.title }}
                              </option> -->
                              <option value="Mr">Mr</option>
                              <option value="Miss">Miss</option>
                              <option value="Mrs">Mrs</option>
                              <option value="Dr.">Dr.</option>
                              <option value="Prof">Prof</option>
                              <option value="other">other</option>
                           </select>
                           <span class="text-sm text-red-600">{{ errors[0] }}</span>
                        </ValidationProvider>
                        </div>
                     </div>
                     <div class="w-full p-2 mb-2">
                        <div class="relative">
                           <label for="lName" class="block mb-2 font-medium">
                              Company
                           </label>
                           <ValidationProvider name="Company" rules="required" v-slot="{ errors }">
                              <input type="text" id="company" v-model="company" name="company" placeholder="Company name" class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-ghc-primary focus:ring-1 focus:ring-ghc-primary" />
                              <span class="text-sm text-red-600">{{ errors[0] }}</span>
                           </ValidationProvider>
                        </div>
                     </div>
                     <div class="w-full p-2 mb-2">
                        <div class="relative">
                           <label for="email" class="block mb-2 font-medium">
                              Email
                           </label>
                           <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                              <input type="email" id="email" v-model="email" name="email" placeholder="Email address" class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-ghc-primary focus:ring-1 focus:ring-ghc-primary" />
                              <span class="text-sm text-red-600">{{ errors[0] }}</span>
                           </ValidationProvider>
                        </div>
                     </div>
                     <!-- <div class="w-full p-2 mb-2">
                        <ValidationProvider name="Terms" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }">
                           <div class="flex items-start">
                              <div class="flex items-center h-5">
                                 <input id="terms" aria-describedby="terms" v-model="terms" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300">
                              </div>
                              <div class="ml-3 text-sm">
                                 <label for="terms" class="font-medium">I agree with the <a href="#" class="text-eta-primary hover:underline">terms and conditions</a>
                                 </label>
                              </div>
                           </div>
                           <span class="text-sm text-red-600 ">{{ errors[0] }}</span>
                        </ValidationProvider>
                     </div> -->

                     <div class="flex justify-end w-full p-2 mt-4">
                        <button type="submit" class="w-full px-4 py-2 text-white transition-colors duration-200 ease-in-out border-0 rounded bg-ghc-primary focus:outline-none hover:opacity-90">
                           Subscribe
                        </button>
                     </div>
                  </div>
               </form>
            </ValidationObserver>
         </div>
      </div>
   </section>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../Api";

export default {
   data() {
      return {
         fName: "",
         lName: "",
         title: "",
         company: "",
         email: "",
      };
   },
   methods: {
      showToast() {
         this.$toast.success("You have subscribed successfully");
      },
      async onSubmit(e) {
         const success = await this.$refs.form.validate();
         if (success) {
            try {
               axios
                  .post(`${baseUrl}/subscriptions`, {
                     firstName: this.fName,
                     lastName: this.lName,
                     title: this.title,
                     company: this.company,
                     email: this.email,
                  })
                  .then(function (response) {
                     console.log(response);
                  })
                  .catch(function (error) {
                     console.log(error);
                  });
               this.$nextTick(() => {
                  // this.$refs.form.reset();
                  // this.fName = "";
                  // this.lName = "";
                  // this.phone = "";
                  // this.email = "";
                  this.showToast();
               });
            } catch (error) {
               console.log({ error });
            }
         } else {
            this.error = true;
         }
      },
   },
};
</script>

<style>
</style>