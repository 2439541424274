<template>
  <div>
    <section class="max-w-6xl px-6 py-20 mx-auto text-gray-600">
      <div class="py-10 text-center">
        <h1 class="text-4xl font-bold text-ghc-primary">
          What Makes Us Different
        </h1>
      </div>
      <div class="text-center">
        <h2 class="mb-3 text-2xl italic font-bold">{{ difference.title }}</h2>
      </div>
      <div>
        <p class="mb-4 text-lg tracking-wide">
          <VueShowdown :markdown="`${difference.content}`" />
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../Api";

export default {
  name: "OurBelief",
  data() {
    return {
      difference: [],
      loading: true,
      error: null,
    };
  },
  async mounted() {
    try {
      const response = await axios.get(`${baseUrl}/what-makes-us-different`);
      this.difference = response.data;
      this.loading = false;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style></style>
