<template>
  <div class="bg-yellow-50">
    <section class="max-w-6xl px-6 py-20 mx-auto text-gray-600">
      <div class="py-10 text-center">
        <h1 class="text-4xl font-bold text-ghc-primary">Our Story</h1>
      </div>
      <div>
        <p class="text-lg tracking-wide">
          <VueShowdown :markdown="`${story.content}`" />
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../Api";

export default {
  name: "OurStory",
  data() {
    return {
      story: [],
      loading: true,
      error: null,
    };
  },
  async mounted() {
    try {
      const story = await axios.get(`${baseUrl}/our-story`);
      this.story = story.data;
      this.loading = false;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style></style>
