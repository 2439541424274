import Vue from "vue";
import VueRouter from "vue-router";
import About from "../views/About.vue";
import Events from "../views/Events.vue";
import Services from "../views/Services.vue";
import Experience from "../views/Experience.vue"
import Resources from "../views/Resources.vue";
import Gallery from "../views/Gallery.vue"
import News from "../views/News.vue"
import Contact from "../views/Contact";
import TermsOfUse from "../views/TermsOfUse";
import PrivacyPolicy from "../views/PrivacyPolicy"; 
import SingleNewsDisplay from "../components/aboutGhc/SingleNewsDisplay";
Vue.use(VueRouter);
// Vue.prototype.$scrollToTop = () =>{
//   window.scrollTo(0, 0)
// } 
const routes = [
  {
    path: "/",
    name: "About GHC",
    component: About,
  },
  // {
  //   path: "/about-us",
  //   name: "About",
  //   component: About,
  // },
  {
    path: "/events",
    name: "Events",
    component: Events
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
  },
  {
    path: "/resources",
    name: "Resources",
    component: Resources
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: Gallery
  },
  {
    path: "/news",
    name: "News",
    component: News
  },
  {
    path: "/news/:id",
    name: "SingleNewsDisplay",
    component: SingleNewsDisplay,
  },
  {
    path: "/experience",
    name: "Experience",
    component: Experience
  },
  {
    path: "/contact-us",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/terms-of-use",
    name: "TermsOfUse",
    component: TermsOfUse,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
