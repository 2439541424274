<template>
  <div>
    <nav
      :class="{ change_color: scrollPosition > 140 }"
      class="fixed top-0 z-10 w-full px-5 py-6 mx-auto text-white bg-trasparent"
    >
      <div class="lg:flex lg:flex-wrap lg:items-center">
        <div class="flex items-center justify-between">
          <a href="/">
            <h1 class="text-6xl font-extrabold">GHC</h1>
          </a>
          <!-- Hamburger menu / mobile -->
          <button
            @click="toggle"
            class="px-3 py-1 border border-gray-400 border-solid rounded opacity-70 hover:opacity-75 lg:hidden"
            id="navbar-toggle"
          >
            <svg
              fill="none"
              stroke="currentColor"
              class="w-6 h-6"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
        <!--  Navigation -->
        <div
          :class="open ? 'flex' : 'hidden'"
          class="flex-col items-center justify-center mt-3 font-medium uppercase lg:flex lg:flex-row lg:ml-auto lg:mt-0"
          id="navbar-collapse"
        >
          <router-link
            v-for="nav in navs"
            :key="nav.name"
            @click.native="scrollToTop"
            :to="nav.route"
            class="py-2 transition-colors duration-300 md:mx-4 hover:text-gray-400"
          >
            {{ nav.name }}
          </router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      scrollPosition: null,
      navs: [
        {
          name: "About GHC",
          route: "/",
        },
        {
          name: "Services",
          route: "/services",
        },
        {
          name: "Experience",
          route: "/experience",
        },
        {
          name: "Events",
          route: "/events",
        },
        {
          name: "Resources",
          route: "/resources",
        },
        {
          name: "Gallery",
          route: "/gallery",
        },
        {
          name: "News",
          route: "/news",
        },
        {
          name: "Contact us",
          route: "/contact-us",
        },
      ],
      open: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    toggle() {
      this.open = !this.open;
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
nav {
  transition: background-color 0.5s ease-in-out;
}
.change_color {
  background-color: #091a22;
}
.change_color_nav {
  background: red;
}
</style>
