var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"text-gray-600"},[_c('div',{staticClass:"max-w-2xl py-20 mx-auto"},[_vm._m(0),_c('div',{staticClass:"p-10 "},[_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full p-2 mb-2 sm:w-1/2"},[_c('div',{staticClass:"relative"},[_c('label',{staticClass:"block mb-2 font-medium",attrs:{"for":"fName"}},[_vm._v(" First Name ")]),_c('ValidationProvider',{attrs:{"name":"First name","rules":"required|alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fName),expression:"fName"}],staticClass:"w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-ghc-primary focus:ring-1 focus:ring-ghc-primary",attrs:{"type":"text","id":"fName","name":"firstName","placeholder":"First name"},domProps:{"value":(_vm.fName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.fName=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"w-full p-2 mb-2 sm:w-1/2"},[_c('div',{staticClass:"relative"},[_c('label',{staticClass:"block mb-2 font-medium",attrs:{"for":"lName"}},[_vm._v(" Last Name ")]),_c('ValidationProvider',{attrs:{"name":"Last name","rules":"required|alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lName),expression:"lName"}],staticClass:"w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-ghc-primary focus:ring-1 focus:ring-ghc-primary",attrs:{"type":"text","id":"lName","name":"lastName","placeholder":"Last name"},domProps:{"value":(_vm.lName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lName=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"w-full p-2 mb-2"},[_c('div',{staticClass:"relative"},[_c('label',{staticClass:"block mb-2 font-medium",attrs:{"for":"title"}},[_vm._v(" Title ")]),_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.title),expression:"title"}],staticClass:"w-full px-3 py-1 text-sm leading-8 text-gray-700 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500",attrs:{"name":"title","id":"title"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.title=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}},[_vm._v("Title")]),_c('option',{attrs:{"value":"Mr"}},[_vm._v("Mr")]),_c('option',{attrs:{"value":"Miss"}},[_vm._v("Miss")]),_c('option',{attrs:{"value":"Mrs"}},[_vm._v("Mrs")]),_c('option',{attrs:{"value":"Dr."}},[_vm._v("Dr.")]),_c('option',{attrs:{"value":"Prof"}},[_vm._v("Prof")]),_c('option',{attrs:{"value":"other"}},[_vm._v("other")])]),_c('span',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"w-full p-2 mb-2"},[_c('div',{staticClass:"relative"},[_c('label',{staticClass:"block mb-2 font-medium",attrs:{"for":"lName"}},[_vm._v(" Company ")]),_c('ValidationProvider',{attrs:{"name":"Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company),expression:"company"}],staticClass:"w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-ghc-primary focus:ring-1 focus:ring-ghc-primary",attrs:{"type":"text","id":"company","name":"company","placeholder":"Company name"},domProps:{"value":(_vm.company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.company=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"w-full p-2 mb-2"},[_c('div',{staticClass:"relative"},[_c('label',{staticClass:"block mb-2 font-medium",attrs:{"for":"email"}},[_vm._v(" Email ")]),_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-ghc-primary focus:ring-1 focus:ring-ghc-primary",attrs:{"type":"email","id":"email","name":"email","placeholder":"Email address"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"flex justify-end w-full p-2 mt-4"},[_c('button',{staticClass:"w-full px-4 py-2 text-white transition-colors duration-200 ease-in-out border-0 rounded bg-ghc-primary focus:outline-none hover:opacity-90",attrs:{"type":"submit"}},[_vm._v(" Subscribe ")])])])])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col w-full mb-4 text-center"},[_c('h1',{staticClass:"text-3xl font-semibold text-ghc-primary"},[_vm._v(" Subscribe to our newsletter ")])])}]

export { render, staticRenderFns }