<template>
  <div class="bg-gray-50">
    <section class="max-w-6xl px-6 py-20 mx-auto mb-10 text-gray-600">
      <div class="py-10 text-center">
        <h1 class="text-4xl font-bold text-ghc-primary">Our Clients</h1>
      </div>
      <div
        class="grid grid-cols-1 gap-5 mx-auto sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3"
      >
        <div
          class="flex items-center justify-center py-10"
          v-for="partner in partners"
          :key="partner.id"
        >
          <a :href="partner.url" target="_blank">
            <img
              :src="getBaseUrl() + partner.image.url"
              :alt="partner.url"
              class="h-14"
            />
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../Api";

export default {
  name: "Partners",
  data() {
    return {
      partners: "",
    };
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
  },
  async mounted() {
    try {
      const partners = await axios.get(`${baseUrl}/clients`);
      this.partners = partners.data;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style scoped></style>
