<template>
  <div>
    <section>
      <div class="max-w-6xl px-5 py-20 mx-auto">
        <div class="flex flex-col w-full mb-20 text-center">
          <div class="py-10 text-center">
            <h1 class="text-4xl font-bold text-ghc-primary">Our Experts</h1>
          </div>
        </div>
        <div class="flex flex-wrap -m-4">
          <div class="p-4 lg:w-1/2" v-for="team in teams" :key="team.id">
            <div
              class="flex flex-col items-center justify-center h-full text-center sm:flex-row sm:justify-start sm:text-left"
            >
              <img
                alt="team"
                class="flex-shrink-0 object-cover object-center w-48 h-48 mb-4 rounded-lg sm:mb-0"
                :src="getBaseUrl() + team.image.url"
              />
              <div class="flex-grow sm:pl-8">
                <h2 class="text-lg font-medium text-gray-900 title-font">
                  {{ team.name }}
                </h2>
                <h3 class="mb-3 text-gray-500">{{ team.role }}</h3>
                <p class="mb-4 text-gray-600">
                  <VueShowdown :markdown="`${team.content}`" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../Api";

export default {
  name: "OurTeam",
  data() {
    return {
      teams: "",
    };
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
  },
  async mounted() {
    try {
      const teams = await axios.get(`${baseUrl}/our-experts`);
      this.teams = teams.data;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style></style>
