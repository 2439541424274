<template>
  <div class="bg-gray-50">
    <section class="max-w-6xl px-5 py-20 mx-auto text-gray-600">
      <div class="py-10 text-center">
        <h1 class="text-4xl font-bold text-ghc-primary">Our Belief</h1>
      </div>
      <div>
        <p class="mb-4 text-lg tracking-wide">
          <VueShowdown :markdown="`${belief.content}`" />
        </p>
        <div class="flex justify-center">
          <a
            :href="belief.learnMore"
            target="_blank"
            class="px-4 py-2 text-center text-white border-0 rounded bg-ghc-primary focus:outline-none hover:opacity-90"
            >learn More
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../Api";

export default {
  name: "OurBelief",
  data() {
    return {
      belief: [],
      loading: true,
      error: null,
    };
  },
  async mounted() {
    try {
      const response = await axios.get(`${baseUrl}/our-belief`);
      this.belief = response.data;
      this.loading = false;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style></style>
