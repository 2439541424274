<template>
   <div>
     <header class="relative bg-fixed bg-center bg-no-repeat bg-cover">
         <!-- Overlay Background + Center Control -->
         <div class="flex items-center justify-center bg-black bg-opacity-50 hero" style="background:rgba(0,0,0,0.5);">
           
         </div>
      </header>
      <div class="max-w-6xl px-4 py-24 mx-auto">
         <div class="pb-10 mx-auto mb-10 lg:w-4/5 sm:flex-row">
            <div class="mb-6">
               <img class="object-cover object-center w-full h-full rounded lg:h-96" :src="getBaseUrl()+news.image.url" :alt="news.title" />
            </div>
            <div class="mt-6 sm:text-left sm:mt-0">
               <h2 class="text-xl font-medium text-gray-900">
                  {{ news.title }}
               </h2>
               <!-- <span class="text-sm text-gray-400">{{ news.publishedDate }}</span> -->
               <p class="mt-3 text-base leading-relaxed">
                  <VueShowdown :markdown="news.content" />
               </p>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { baseUrl } from "../../Api";
import axios from "axios";

export default {
   name: "SingleNewsDisplay",
   data() {
      return {
         loading: true,
         news: {},
      };
   },
   methods: {
      getBaseUrl() {
         return baseUrl;
      },
   },
   async created() {
      if (this.$route.params.news === undefined) {
         try {
            const response = await axios.get(`${baseUrl}/latest-news`);
            this.news = response.data.find(
               (news) => news.id === this.$route.params.id
            );
            this.loading = false;
         } catch (error) {
            this.error = error;
         }
      } else {
         this.news = this.$route.params.news;
         this.loading = false;
      }
   },
};
</script>

<style scoped>
header {
   background-image: url("../../assets/header.png");
   min-height: 170px;
}
.hero {
   min-height: 170px;
}</style>
