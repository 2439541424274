<template>
  <div>
    <header class="relative bg-fixed bg-center bg-no-repeat bg-cover">
      <!-- Overlay Background + Center Control -->
      <div
        class="flex items-center justify-center bg-black bg-opacity-50 hero"
        style="background: rgba(0, 0, 0, 0.5)"
      >
        <div class="flex flex-col mx-2 space-y-6 text-center">
          <h1 class="text-3xl text-gray-100 uppercase md:text-4xl">Services</h1>
        </div>
      </div>
    </header>
    <section class="max-w-6xl px-6 py-20 mx-auto text-gray-600">
      <div class="py-10 text-center">
        <h1 class="text-4xl font-bold text-ghc-primary">Our Services</h1>
      </div>
      <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div
          class="px-6 mx-3 mt-8 text-center border-2 border-gray-200 border-dashed hover:border-ghc-borderhover py-14 rounded-xl"
          v-for="service in services"
          :key="service.id"
        >
          <div class="relative inline-block services_icon">
            <img
              class="w-full icon"
              :src="getBaseUrl() + service.image.url"
              :alt="service.url"
            />
            <!-- <svg class="w-6 h-6 icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                     <path stroke-linecap="round" stroke-linejoin="round" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                  </svg> -->
            <!-- width="234" height="232" -->
            <svg width="172" height="170" viewBox="0 0 94 92">
              <path
                class="services_shape"
                id="Polygon_12"
                stroke="#cea055"
                data-name="Polygon 12"
                d="M42.212,2.315a11,11,0,0,1,9.576,0l28.138,13.6a11,11,0,0,1,5.938,7.465L92.83,54.018A11,11,0,0,1,90.717,63.3L71.22,87.842A11,11,0,0,1,62.607,92H31.393a11,11,0,0,1-8.613-4.158L3.283,63.3A11,11,0,0,1,1.17,54.018L8.136,23.383a11,11,0,0,1,5.938-7.465Z"
              ></path>
            </svg>
          </div>
          <div class="mt-5 services_content xl:mt-10">
            <h2
              class="mb-6 text-2xl font-semibold text-ghc-primary services_title md:text-2xl lg:text-xl xl:text-3xl"
            >
              {{ service.serviceTitle }}
            </h2>
            <div class="justify-center max-w-xs mx-auto text-left">
              <div
                class="flex mb-3 space-x-2"
                v-for="serviceList in service.service"
                :key="serviceList.id"
              >
                <svg
                  class="flex-shrink-0 w-5 h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#cea055"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m9 12 2 2 4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0z"
                  />
                </svg>
                <p>
                  {{ serviceList.title }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
  name: "Services",
  data() {
    return {
      services: [],
    };
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
  },
  async mounted() {
    try {
      const services = await axios.get(`${baseUrl}/services`);
      this.services = services.data;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style scoped>
header {
  background-image: url("../assets/header.png");
  min-height: 500px;
}
.hero {
  min-height: 500px;
}
.icon {
  font-size: 3rem;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(0) skewX(0) skewY(0)
    scaleX(1) scaleY(1);
}
.services_shape {
  fill: rgba(206, 160, 85, 0.18);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}
</style>
