<template>
  <div>
    <header class="relative bg-fixed bg-center bg-no-repeat bg-cover">
      <!-- Overlay Background + Center Control -->
      <div
        class="flex items-center justify-center bg-black bg-opacity-50 hero"
        style="background: rgba(0, 0, 0, 0.5)"
      >
        <div class="flex flex-col mx-2 space-y-6 text-center">
          <h1 class="text-3xl text-gray-100 uppercase md:text-4xl">
            Experiences
          </h1>
        </div>
      </div>
    </header>
    <section class="max-w-6xl px-6 py-20 mx-auto text-gray-600">
      <div class="py-10 text-center">
        <h1 class="text-4xl font-bold text-ghc-primary">Our Experiences</h1>
      </div>
      <div
        class="grid items-center grid-cols-1 my-48 mt-8 md:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24"
        v-for="(product, index) in items"
        :key="product.id"
      >
        <div
          class="order-1"
          :class="index % 2 === 0 ? 'md:order-1' : 'md:order-2'"
        >
          <div class="flex flex-col justify-between">
            <h2
              class="mb-3 text-2xl font-semibold text-ghc-primary md:text-2xl lg:text-xl xl:text-3xl"
            >
              {{ product.experienceTitle }}
            </h2>
          </div>
          <div class="justify-center mx-auto">
            <div
              class="flex mb-2 space-x-2"
              v-for="experienceList in product.experienceList"
              :key="experienceList.id"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="flex-shrink-0 w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#cea055"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <p>
                {{ experienceList.title }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="w-full h-full overflow-hidden rounded"
          :class="index % 2 === 0 ? 'order-2' : 'order-1'"
        >
          <img alt="pp" :src="getBaseUrl() + product.image.url" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
  name: "Experience",
  data() {
    return {
      items: [],
      loading: true,
    };
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
  },
  async mounted() {
    try {
      const response = await axios.get(`${baseUrl}/experiences`);
      this.items = response.data;
      this.loading = false;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style scoped>
header {
  background-image: url("../assets/header.png");
  min-height: 500px;
}
.hero {
  min-height: 500px;
}
</style>
