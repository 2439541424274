<template>
   <div>
      <header class="relative bg-fixed bg-center bg-no-repeat bg-cover">
         <!-- Overlay Background + Center Control -->
         <div class="flex items-center justify-center bg-black bg-opacity-50 hero" style="background:rgba(0,0,0,0.5);">
            <div class="flex flex-col mx-2 space-y-6 text-center">
               <h1 class="text-3xl text-gray-100 uppercase md:text-4xl">
                  Resources
               </h1>
            </div>
         </div>
      </header>
      <section class="max-w-6xl px-6 py-20 mx-auto text-gray-600">
         <div class="py-10 text-center">
            <h1 class="text-4xl font-bold text-ghc-primary">
               Insights
            </h1>
         </div>
         <div>
            <p>
               In learning what works in healthcare in Africa and what needs to change,
               we are excited to share our findings. Coming soon!
            </p>
         </div>
      </section>
      <section class="max-w-6xl px-6 py-10 mx-auto text-ghc-primary">
         <h2 class="mb-4 text-4xl font-bold text-eta-primary">
            Resources
         </h2>
         <div class="overflow-hidden text-gray-600">
            <div class="container mx-auto">
               <div class="divide-y-2 divide-gray-100">
                  <div class="flex flex-wrap py-8 md:flex-nowrap" v-for="resource in resources" :key="resource.id">

                     <div class="leading-relaxed">
                        {{ resource.name}}
                        <a :href="resource.link" target="_blank" class="cursor-pointer hover:underline hover:text-ghc-primary">{{ resource.link }}</a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
   name: "Resources",
   data() {
      return {
         resources: "",
      };
   },
   methods: {
      getBaseUrl() {
         return baseUrl;
      },
   },
   async mounted() {
      try {
         const resources = await axios.get(`${baseUrl}/resources`);
         this.resources = resources.data;
      } catch (error) {
         this.error = error;
      }
   },
};
</script>

<style scoped>
header {
   background-image: url("../assets/header.png");
   min-height: 500px;
}
.hero {
   min-height: 500px;
}
</style>