<template>
  <div>
    <header class="relative bg-fixed bg-center bg-no-repeat bg-cover">
      <!-- Overlay Background + Center Control -->
      <div
        class="flex items-center justify-center bg-black bg-opacity-50 hero"
        style="background: rgba(0, 0, 0, 0.5)"
      >
        <div class="flex flex-col mx-2 space-y-6 text-center">
          <h1 class="text-3xl text-gray-100 uppercase md:text-4xl">Events</h1>
        </div>
      </div>
    </header>
    <section class="max-w-6xl px-6 py-20 mx-auto text-gray-600">
      <!-- <div class="py-10 text-center">
            <h1 class="text-4xl font-bold text-ghc-primary">
               Events
            </h1>
         </div> -->
      <div class="overflow-hidden text-gray-600">
        <div class="container px-5 mx-auto">
          <div class="inline-flex">
            <select
              name="filter"
              v-model="filter"
              id="filter"
              class="w-full px-8 py-1 text-gray-700 transition-colors duration-200 ease-in-out border border-gray-400 rounded outline-none hover:bg-gray-200 focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
            >
              <option value="">Filter</option>
              <option value="name">By Name</option>
              <option value="date">By Date</option>
            </select>
          </div>
          <div
            v-if="filter === 'name'"
            class="p-6 mt-4 bg-gray-100 rounded shadow"
          >
            <label for="name" class="block mb-2 text-sm">Filter By Name</label>
            <input
              v-model="name"
              type="text"
              class="w-64 px-3 py-1 placeholder-gray-400 border rounded lg:w-96 hover:border-gray-400"
              placeholder="Name of event"
            />
            <button
              @click="clearFilterByName"
              class="px-3 py-1 ml-6 bg-gray-300 border border-gray-400 rounded hover:bg-gray-400 hover:text-gray-100"
            >
              Clear filter
            </button>
          </div>
          <div
            v-if="filter === 'date'"
            class="flex flex-wrap p-6 mt-4 bg-gray-100 rounded shadow md:space-x-10"
          >
            <div>
              <label for="strtDate" class="block mb-2 text-sm"
                >Start Date</label
              >
              <input
                v-model="startDate"
                type="date"
                class="px-3 py-1 border rounded hover:border-gray-400"
                placeholder="start date"
              />
            </div>
            <div class="mx-6 md:ml-none">
              <label for="endDate" class="block mb-2 text-sm">End Date</label>
              <input
                v-model="endDate"
                type="date"
                class="px-3 py-1 border rounded appearance-none hover:border-gray-400"
                placeholder="end date"
              />
            </div>
            <div
              class="flex flex-col-reverse mt-4 md:mt-0 md:ml-6 md:items-end"
            >
              <button
                @click="clearFilterByDate"
                class="px-3 py-1 bg-gray-300 border border-gray-400 rounded hover:bg-gray-400 hover:text-gray-100"
              >
                Clear filter
              </button>
            </div>
          </div>
        </div>
        <div class="container px-5 py-24 mx-auto">
          <div class="-my-8 divide-y-2 divide-gray-100">
            <div
              class="flex flex-wrap py-8 md:flex-nowrap"
              v-for="event in filterdEvents"
              :key="event.id"
            >
              <div class="flex flex-col flex-shrink-0 mb-6 md:w-64 md:mb-0">
                <span class="font-semibold text-gray-700 title-font">{{
                  event.catagory
                }}</span>
                <span class="mt-1 text-sm text-gray-500">
                  {{ event.date }}</span
                >
              </div>
              <div class="md:flex-grow">
                <h2 class="mb-2 text-2xl font-medium text-gray-900 title-font">
                  {{ event.title }}
                </h2>
                <p class="leading-relaxed">
                  <VueShowdown :markdown="event.details" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
  name: "Events",
  data() {
    return {
      events: [],
      filter: "",
      name: "",
      startDate: "",
      endDate: "",
    };
  },
  computed: {
    getBaseUrl() {
      return baseUrl;
    },
    filterEventsByDate: function () {
      let filterdEvents = this.events;
      if (
        this.startDate !== "" &&
        this.endDate !== "" &&
        this.startDate &&
        this.endDate
      ) {
        let start = new Date(`${this.startDate}`).getTime();
        let end = new Date(`${this.endDate}`).getTime();
        filterdEvents = filterdEvents.filter((event) => {
          return (
            new Date(`${event.date}`).getTime() >= start &&
            new Date(`${event.date}`).getTime() <= end
          );
        });
      }
      return filterdEvents;
    },
    filterEventsByName: function () {
      let filterdEvents = this.events;
      if (this.name !== "" && this.name) {
        filterdEvents = filterdEvents.filter((event) => {
          return event.title.toUpperCase().includes(this.name.toUpperCase());
        });
      }
      return filterdEvents;
    },

    filterdEvents() {
      return this.filter === "name"
        ? this.filterEventsByName
        : this.filterEventsByDate;
    },
  },
  methods: {
    clearFilterByName() {
      this.name = "";
    },
    clearFilterByDate() {
      this.startDate = "";
      this.endDate = "";
    },
  },
  async mounted() {
    try {
      const events = await axios.get(`${baseUrl}/events`);
      this.events = events.data.sort(
        (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
      );
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style scoped>
header {
  background-image: url("../assets/header.png");
  min-height: 500px;
}
.hero {
  min-height: 500px;
}
</style>
