<template>
   <div>
      <header class="relative w-full h-full bg-fixed bg-center bg-no-repeat bg-cover">
         <!-- Overlay Background + Center Control -->
         <div class="flex items-center justify-center bg-black bg-opacity-50 hero" style="background:rgba(0,0,0,0.5);">
            <div class="flex flex-col mx-2 space-y-6 text-center">
               <h1 class="text-3xl text-gray-100 uppercase md:text-4xl">
                  Contact us
               </h1>
            </div>
         </div>
      </header>
      <section class="relative max-w-6xl px-6 py-20 mx-auto text-gray-600">
         <div class="flex-wrap mx-auto md:flex">
            <div class="relative flex items-end justify-start p-10 overflow-hidden bg-gray-300 rounded-sm h-96 lg:h-auto lg:w-2/3 md:w-full">
               <iframe width="100%" height="100%" class="absolute inset-0" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=7412%20Eldorado%20Street%20McLean,%20VA%2022102+()&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
               </iframe>
               <div class="relative flex flex-wrap py-6 bg-white rounded shadow-md opacity-0 md:opacity-0">
                  <div class="px-6 lg:w-1/2">
                     <h2 class="text-xs font-semibold tracking-widest text-gray-900">
                        PHYSICAL ADDRESS
                     </h2>
                     <p class="mt-1">
                        address
                     </p>
                  </div>
                  <div class="px-6 mt-4 lg:w-1/2 lg:mt-0">
                     <h2 class="text-xs font-semibold tracking-widest text-gray-900 title-font">
                        EMAIL
                     </h2>
                     <a class="leading-relaxed text-indigo-500"> email </a>
                     <h2 class="mt-4 text-xs font-semibold tracking-widest text-gray-900 title-font">
                        PHONE
                     </h2>
                     <p class="leading-relaxed"> phone number </p>
                  </div>
               </div>
            </div>
            <div class="flex flex-col w-full mt-8 bg-white lg:pl-10 lg:w-1/3 md:py-8 lg:py-0 md:mt-0 sm:w-4/5 sm:mx-auto">
               <h1 class="mb-1 text-3xl font-bold text-ghc-primary">Contact us</h1>
               <p class="mb-3 leading-relaxed">Leave us a message here</p>
               <ValidationObserver ref="form">
                  <form @submit.prevent="onSubmit">
                     <div class="relative mb-4">
                        <label for="name" class="text-sm leading-7 text-gray-600">
                           Name
                        </label>
                        <ValidationProvider name="Name" rules="required|alpha" v-slot="{ errors }">
                           <input type="text" id="name" v-model="name" name="name" placeholder="Name" class="w-full px-3 py-1 text-sm leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded-sm outline-none focus:border-ghc-primary focus:bg-white" />
                           <span class="text-sm text-red-600">{{ errors[0] }}</span>
                        </ValidationProvider>
                     </div>
                     <div class="relative mb-4">
                        <label for="email" class="text-sm leading-7 text-gray-600">Email</label>
                        <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                           <input type="email" id="email" v-model="email" name="email" placeholder="Email" class="w-full px-3 py-1 text-sm leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded-sm outline-none focus:border-ghc-primary focus:bg-white" />
                           <span class="text-sm text-red-600">{{ errors[0] }}</span>
                        </ValidationProvider>
                     </div>
                     <div class="relative mb-4">
                        <label for="message" class="text-sm leading-7 text-gray-600">Message</label>
                        <ValidationProvider name="Message" rules="required" v-slot="{ errors }">
                           <textarea id="message" v-model="message" name="message" placeholder="Message" class="w-full h-32 px-3 py-2 text-base leading-6 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-white border border-gray-400 rounded-sm outline-none focus:border-ghc-primary focus:bg-white"></textarea>
                           <span class="text-sm text-red-600">{{ errors[0] }}</span>
                        </ValidationProvider>
                     </div>
                     <button loading="true" type="submit" value="send" class="w-full px-6 py-3 text-white transition-colors duration-200 ease-in-out border-0 rounded-sm bg-ghc-primary focus:outline-none hover:opacity-90">
                        Send Message
                     </button>
                  </form>
               </ValidationObserver>
            </div>
         </div>
      </section>
   </div>
</template>

<script>
import axios from "axios";
import emailjs from "emailjs-com";
import { baseUrl } from "../Api";

export default {
   name: "Contact",
   data: () => ({
      successMessage: false,
      items: [],
      valid: true,
      name: "",
      email: "",
      message: "",
      error: false,
      loading: false,
      snackbar: false,
      snackbar_message: "",
   }),
   async mounted() {
      try {
         const response = await axios.get(`${baseUrl}/contacts`);
         this.items = response.data;
      } catch (error) {
         this.error = error;
      }
   },
   methods: {
      showToast() {
         this.$toast.success("Message sent sucessfully");
      },
      async onSubmit(e) {
         const success = await this.$refs.form.validate();
         try {
            if (success) {
               emailjs.sendForm(
                  "service_zlnw8io",
                  "template_b0f7soj",
                  e.target,
                  "user_pHlXRSBk1ewiAuGzuc7jE"
               );
               this.$nextTick(() => {
                  this.$refs.form.reset();
                  this.name = "";
                  this.email = "";
                  this.message = "";
                  this.showToast();
               });
            } else {
               this.error = true;
               // console.log("Coudln't post mail");
            }
         } catch (error) {
            console.log({ error });
         }
         // Reset form field
         // this.name = "";
         // this.email = "";
         // this.message = "";
      },
      // reset() {
      //   (this.name = ""), (this.message = ""), (this.email = "");
      //   this.success = false;
      // },
   },
};
</script>

<style scoped>
header {
   background-image: url("../assets/header.png");
}
.hero {
   min-height: 500px;
}
</style>
